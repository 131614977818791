<template>
  <section>
    <b-card>
      <TablePagination
        v-model="items"
        url="/admin/affiliate/all/paginate"
        :per-page="10"
        :fields="fields"
      >
        <template v-slot:name="data">
          {{ data.items.user.personal_data.full_name }}
        </template>

        <template v-slot:amount="data">
          {{ data.items.commission | currency }}
        </template>

        <template v-slot:type="data">
          <b-button
            size="sm"
            :variant="getType(data.items.type).color"
          >
            {{ getType(data.items.type).message }}
          </b-button>
        </template>

        <template v-slot:pix_sender="data">
          {{ data.items.pix_sender }}
        </template>

        <template v-slot:pix_address="data">
          {{ data.items.pix_address }}
        </template>

        <template v-slot:actions="data">
          <div v-if="data.items.status < 3">
            <b-button
              class="mr-1"
              size="sm"
              variant="relief-success"
              :disabled="loading"
              @click.stop="setStatus(data.items, 4)"
            >
              <feather-icon
                v-if="!loading"
                icon="CheckIcon"
              />
              <b-spinner
                v-else
                small
              />
            </b-button>

            <b-button
              class="mr-1"
              size="sm"
              :disabled="data.items.status === 2 || loading"
              variant="relief-warning"
              @click.stop="setStatus(data.items, 2)"
            >
              <feather-icon
                v-if="!loading"
                icon="ClockIcon"
              />
              <b-spinner
                v-else
                small
              />
            </b-button>

            <b-button
              size="sm"
              variant="relief-danger"
              :disabled="loading"
              @click.stop="setStatus(data.items, 3)"
            >
              <feather-icon
                v-if="!loading"
                icon="XCircleIcon"
              />
              <b-spinner
                v-else
                small
              />
            </b-button>
          </div>

          <div v-if="data.items.status >= 3">
            <b-button
              size="sm"
              :disabled="loading"
              :variant="getStatus(data.items.status).color"
            >
              <span v-if="!loading">{{ getStatus(data.items.status).message }}</span>
              <b-spinner
                v-else
                small
              />
            </b-button>
          </div>
        </template>

        <template v-slot:created_at="data">
          <b-button
            size="sm"
            variant="relief-primary"
          >
            {{ data.items.created_at | date }}
          </b-button>
        </template>
      </TablePagination>
    </b-card>
  </section>
</template>

<script>
import { BCard, BButton, BSpinner } from 'bootstrap-vue'
import moment from 'moment'
import TablePagination from '@/components/TablePagination/Index.vue'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    TablePagination
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 2)
    },
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  data: () => ({
    loading: false,
    items: [],
    fields: [
      {
        key: 'created_at',
        label: 'Data'
      },
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'amount',
        label: 'Valor'
      },
      {
        key: 'type',
        label: 'Tipo'
      },
      {
        key: 'pix_sender',
        label: 'Remetente'
      },
      {
        key: 'pix_address',
        label: 'Chave PIX'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  }),
  methods: {
    getType (type) {
      const response = {
        message: '',
        color: ''
      }

      switch (type) {
      case 1:
        response.message = 'Saldo em Conta'
        response.color = 'relief-primary'
        break

      case 2:
        response.message = 'PIX'
        response.color = 'relief-primary'
        break

      default:
        response.message = 'Não definido'
        response.color = 'relief-primary'
      }

      return response
    },
    getStatus (status) {
      const response = {
        message: '',
        color: ''
      }

      switch (status) {
      case 3:
        response.message = 'Recusado'
        response.color = 'relief-danger'
        break

      case 4:
        response.message = 'Aprovado'
        response.color = 'relief-success'
        break

      default:
        response.message = 'Não definido'
        response.color = 'relief-primary'
      }

      return response
    },
    setStatus (item, status) {
      this.$bvModal
        .msgBoxConfirm('Tem certeza que deseja alterar o status?', {
          title: 'Confirmação',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelVariant: 'secondary',
          cancelTitle: 'Não',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.setStatusConfirmed(item, status)
          }
        })
    },
    setStatusConfirmed (item, status) {
      let url = ''
      this.loading = true

      switch (status) {
      case 2:
        url = `/admin/affiliate/withdrawal/set_analyze/${item.id}`
        break

      case 3:
        url = `/admin/affiliate/withdrawal/set_decline/${item.id}`
        break

      case 4:
        url = `/admin/affiliate/withdrawal/set_approved/${item.id}`
        break

      default:
        url = ''
        break
      }

      this.$http.get(url).finally(() => {
        const index = this.items.findIndex(i => i.id === item.id)
        this.items[index].status = status
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
